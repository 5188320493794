
export const globalInitialState = {
    globalInitialState: 'Initial value',
    user: null
}

export const initialState = {
    initialState: 'initial'
}


export const MainReducer = ( state = initialState, action ) => {
    console.log('RECEIVED ACTION', state, action)
    switch( action.type ) {
        case 'UPDATE_PARTIAL':
            return {
                ...state,
                ...{...action.payload}
            }
        default:
            return state
    }
}