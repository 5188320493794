import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Highlight';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import {Route} from 'react-router-dom'

import {Auth} from 'aws-amplify'

const styles = {
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
};

const MenuAppBar = (props) => {
    const initialLocalState = {
        anchorEl: null
    };

    const [localState, seLocalState] = useState(initialLocalState)

    const handleMenu = event => {
        seLocalState({...localState, anchorEl: event.currentTarget});
    };

    const handleClose = () => {
        seLocalState({...localState, anchorEl: null});
    };

    const handleSignOut = () => {
        Auth.signOut() //todo: later we need to redirect to login when signed out
            .then(data => console.log(data))
            .catch(err => console.log(err))
    }

    const {classes} = props;
    const {anchorEl} = localState;
    const open = Boolean(anchorEl);


    return (
        <Route
            render={({history}) => (

                <div className={classes.root}>
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
                                <MenuIcon/>
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.grow}>
                                {/*{document.title}*/}
                                Welcome.
                            </Typography>
                            <small id="versionNumber">V {process.env.REACT_APP_VERSION}</small>

                            <Tab onClick={handleSignOut} label="Sign Out!"/>

                            {/*<Tabs variant="fullWidth" value={'Dashboard'} onChange={(e,v)=>history.push(v)}>*/}
                                {/*<Tab value={'Dashboard'} label="Dashboard"/>*/}
                                {/*<Tab value={'Profile'} label="Profile"/>*/}
                            {/*</Tabs>*/}
                            {/*<div>*/}
                                {/*<IconButton*/}
                                    {/*aria-owns={open ? 'menu-appbar' : undefined}*/}
                                    {/*aria-haspopup="true"*/}
                                    {/*onClick={handleMenu}*/}
                                    {/*color="inherit"*/}
                                {/*>*/}
                                    {/*<AccountCircle/>*/}
                                {/*</IconButton>*/}
                                {/*<Menu*/}
                                    {/*id="menu-appbar"*/}
                                    {/*anchorEl={anchorEl}*/}
                                    {/*anchorOrigin={{*/}
                                        {/*vertical: 'top',*/}
                                        {/*horizontal: 'right',*/}
                                    {/*}}*/}
                                    {/*transformOrigin={{*/}
                                        {/*vertical: 'top',*/}
                                        {/*horizontal: 'right',*/}
                                    {/*}}*/}
                                    {/*open={open}*/}
                                    {/*onClose={handleClose}*/}
                                {/*>*/}
                                    {/*<MenuItem onClick={handleClose}>Profile</MenuItem>*/}
                                    {/*<MenuItem onClick={handleClose}>Dashboard</MenuItem>*/}
                                    {/*<MenuItem onClick={handleSignOut}>Sign out</MenuItem>*/}
                                {/*</Menu>*/}
                            {/*</div>*/}
                        </Toolbar>
                    </AppBar>
                </div>
            )}
        />
    );

}

export default withStyles(styles)(MenuAppBar);
