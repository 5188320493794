import React, {useState, useContext, useEffect, useReducer} from 'react'
import Context from '../context'
import logo from '../img/logo_01.png'
import './AllInOne.css'
import LinkIcon from '@material-ui/icons/Link'
import LaunchIcon from '@material-ui/icons/Launch'

import CircularProgress from '@material-ui/core/CircularProgress';
import {Auth} from "aws-amplify";
import axios from "axios";

import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import WifiIcon from '@material-ui/icons/Wifi';
import Typography from '@material-ui/core/Typography';
import SnackBar from '@material-ui/core/Snackbar'
import SnackBarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import Favorite from '@material-ui/icons/Favorite'

import classNames from 'classnames'
import ReactGA from 'react-ga';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


const PARTIAL = 'partial'


const localReducer = (state, [type, payload]) => {
    switch (type) {
        case 'initial':
            return state
        case PARTIAL:
            console.log('setting partial state', state)
            return {...state, ...{...payload}}
        default:
            return state
    }
}

const initialState = {
    isPageLoading: false,
    fxLogoStart: false,
    hueAccConnected: false,
    hueAccConnectMsg: '',
    isHueAuthProcessing: false,
    isHueAuthStarted: false,
    allOkToGetLights: false,
    lights: null,
}
const AllInOne = () => {

    ReactGA.initialize('UA-62260390-2');

    const [state, dispatch] = useReducer(localReducer, initialState)

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        dispatch([PARTIAL, {fxLogoStart: true, isPageLoading: true}])
        handleCheckUser()
    }, [])

    useEffect(() => {
        console.log('NOW WE SHOULD GET THE LIST OF LIGHTS IF NOT THERE ALREADY!')
    }, [state.allOkToGetLights])


    const handleCheckUser = () => {

        Auth.currentAuthenticatedUser()
            .then(user => {
                axios.get(`${(process.env.REACT_APP_KUGELBLITZ_API).replace(/\s/g, '')}/checkUserCreds`,
                    {
                        headers: {"Authorization": user.signInUserSession.idToken.jwtToken}
                    })
                    .then(res => {
                        console.log('got user:', res.data)
                        dispatch([PARTIAL, {
                            isPageLoading: false,
                            hueAccConnected: true,
                            allOkToGetLights: true,
                            lights: res.data.data
                        }])
                    })
                    .catch(err => { //todo: show a toast message to user
                        dispatch([PARTIAL, {isPageLoading: false, hueAccConnected: false,}])
                        if (err.response && err.response.status === 404 && err.response.data.errorCode === "404") dispatch([PARTIAL, {
                            hueAccConnectMsg: ''
                        }])
                        if (err.response && err.response.status === 409 && err.response.data.errorCode === "409") dispatch([PARTIAL, {
                            hueAccConnectMsg: 'Connection to your MeetHue account seems to be broken! please reconnect your accounts.'
                        }])
                    })
            })
            .catch(err => {
                console.log('err:', err)
            })
    }


    const handleFetchStuff = (stuff) => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                axios.get(`${(process.env.REACT_APP_KUGELBLITZ_API).replace(/\s/g, '')}/${stuff}`,
                    {
                        headers: {"Authorization": user.signInUserSession.idToken.jwtToken}
                    })
                    .then(res => {
                        console.log(`>>>>>>>>>>Got ${stuff}:`, res.data)
                        ReactGA.event({
                            category: 'handleFetchStuff',
                            action: stuff,
                            value: res.data
                        });
                        dispatch([PARTIAL, {
                            [stuff]: res.data.data
                        }])
                    })
                    .catch(err => { //todo: show a toast message to user
                        console.log('ERRRRRRR:', err)
                        ReactGA.event({
                            category: 'ERR',
                            action: stuff,
                            label: 'Error fetching stuff!',
                            value: err.message
                        });
                    })
            })
            .catch(err => {
                console.log('err:', err)
            })
    }


    const handleHueAuthStart = () => {
        dispatch([PARTIAL, {isHueAuthStarted: true, isHueAuthProcessing: true}])
        Auth.currentAuthenticatedUser()
            .then(user => {
                axios.post(`${(process.env.REACT_APP_KUGELBLITZ_AUTH).replace(/\s/g, '')}/auth_start`,
                    {}, {headers: {"Authorization": user.signInUserSession.idToken.jwtToken}})
                    .then(res => {
                        dispatch([PARTIAL, {isHueAuthProcessing: false}])
                        window.open(
                            res.data.authUrl,
                            // "_blank"
                        )
                        console.log('RESULTS::::: >>>>', res.data.authUrl)
                    })
                    .catch(err => console.log('Error starting the Auth process:', err))
            })
            .catch(err => console.log('ERR:', err))
    }

    const lightToggleEffect = (payload) => {
        console.log(`Toggle the effect for the light number ${payload.target.value}`)
        const lightNumber = payload.target.value
        let selectedLight = state.lights[lightNumber]
        const newFx = selectedLight.state.effect === 'colorloop' ? 'none' : 'colorloop'
        dispatch([PARTIAL, {
            lights: {
                ...state.lights,
                [lightNumber]: {...selectedLight, isLoading: true, isFxLoading: true} //todo
            }
        }])
        Auth.currentAuthenticatedUser()
            .then(user => {
                axios.put(`${(process.env.REACT_APP_KUGELBLITZ_API).replace(/\s/g, '')}/lights/${lightNumber}/state`,
                    {
                        "effect": newFx
                    },
                    {
                        headers: {"Authorization": user.signInUserSession.idToken.jwtToken}
                    })
                    .then(res => {
                        if (!res.data.data[0].error) {
                            console.log('these are the results:::: ////// // // / // ', res.data.data[0].success)
                            selectedLight.state.effect = newFx
                            selectedLight.isFxLoading = false
                            selectedLight.isLoading = false
                            let lights = {
                                ...state.lights,
                                [lightNumber]: selectedLight
                            }
                            dispatch([PARTIAL, {lights}])
                        }
                    })
                    .catch(err => { //todo: show a toast message to user
                        console.log('ERRRRRRR:', err)
                        dispatch([PARTIAL, {
                            lights: {
                                ...state.lights,
                                [lightNumber]: {...selectedLight, isLoading: false, isFxLoading: true}
                            }
                        }])
                    })
            })
            .catch(err => {
                console.log('err:', err)
                dispatch([PARTIAL, {
                    lights: {
                        ...state.lights,
                        [lightNumber]: {...selectedLight, isLoading: false, isFxLoading: true}
                    }
                }])
            })
    }

    const lightToggle = (lightNumber) => {
        let selectedLight = state.lights[lightNumber]
        dispatch([PARTIAL, {
            lights: {
                ...state.lights,
                [lightNumber]: {...selectedLight, isLoading: true}
            }
        }])
        Auth.currentAuthenticatedUser()
            .then(user => {
                axios.put(`${(process.env.REACT_APP_KUGELBLITZ_API).replace(/\s/g, '')}/lights/${lightNumber}/state`,
                    {
                        "on": !selectedLight.state.on
                    },
                    {
                        headers: {"Authorization": user.signInUserSession.idToken.jwtToken}
                    })
                    .then(res => {
                        if (!res.data.data[0].error) {
                            console.log('NOW UPDATING THE FUCKING STATE!')
                            selectedLight.state.on = !selectedLight.state.on
                            selectedLight.isLoading = false
                            let lights = {
                                ...state.lights,
                                [lightNumber]: selectedLight
                            }
                            dispatch([PARTIAL, {lights}])
                        }
                    })
                    .catch(err => { //todo: show a toast message to user
                        console.log('ERRRRRRR:', err)
                        dispatch([PARTIAL, {
                            lights: {
                                ...state.lights,
                                [lightNumber]: {...selectedLight, isLoading: false}
                            }
                        }])
                    })
            })
            .catch(err => {
                console.log('err:', err)
                dispatch([PARTIAL, {
                    lights: {
                        ...state.lights,
                        [lightNumber]: {...selectedLight, isLoading: false}
                    }
                }])
            })
    }

    console.log('state::::', state)
    return (
        <div>
            <div id="allInOneLogo">
                <Grow in={state.fxLogoStart} {...(state.fxLogoStart ? {timeout: 666} : {})}><img src={logo} alt="Logo"/></Grow>
                <h2>{document.title}</h2>
            </div>


            <div className="allInOneSection">
                {state.isPageLoading && <Spinner/>}

                {(!state.isPageLoading && !state.hueAccConnected) &&
                <div id="hueConnectCard">
                    <LinkIcon id="linkIcon"/>
                    {!state.isHueAuthStarted &&
                    <>
                        <h2>To Start:</h2>
                        < span>
                            < Button variant="outlined" color="inherit" onClick={handleHueAuthStart}>
                            <span id="hueConnectBtnTxt">Click here to connect your Meet-Hue accounts!</span>
                            <LaunchIcon/>
                            </Button><br/>
                            <small>If you don't have an account, you can create one at the same time!</small>
                        </span>
                    </>
                    }
                    {state.isHueAuthStarted &&
                    <>
                        <h2>{state.isHueAuthProcessing ? "Processing ..." : "Opening Meet Hue..."}</h2>
                        < span>
                            {!state.isHueAuthProcessing &&
                            <>
                                < Button variant="outlined" color="inherit" onClick={() => {
                                    console.log('TODO!')
                                }}>
                                    <span id="hueConnectBtnTxt">Click me after connecting your Hue account</span>
                                    <LaunchIcon/></Button><br/>
                                <small>If you don't have an account, you can create one at the same page!</small>
                            </>
                            }
                        </span>
                    </>
                    }
                </div>
                }

                {state.lights &&
                <List subheader={<ListSubheader disableSticky>Lights</ListSubheader>}>
                    {Object.entries(state.lights).map((light => {
                        // console.log(light[1].state)
                        const lightIcon = (!light[1].state.reachable) ?
                            'lightbulb-exclamation' :
                            ((light[1].state.on) ? 'lightbulb-on' : 'lightbulb')
                        const lightDescription = (!light[1].state.reachable) ?
                            'The light is UnReachable!' :
                            ((light[1].state.on) ? 'The light is on!' : 'The light is off!')
                        return (
                            <ListItem
                                className={classNames('lightsItem', {
                                    unreachableLight: !light[1].state.reachable,
                                    reachableLight: light[1].state.reachable
                                })}
                                key={light[0]} alignItems="flex-start"
                                onClick={() => lightToggle(light[0])}
                                disabled={!light[1].state.reachable}
                            >
                                <ListItemIcon className='lightIconLeft'>
                                    <span className='fa-layers fa-fw'>
                                        <FontAwesomeIcon
                                            icon={['fal', lightIcon]}
                                            size='2x'
                                            fixedWidth
                                            color={(light[1].state.reachable) ?
                                                light[1].state.on ? 'white' : 'black'
                                                : '#333'}
                                            title={lightDescription}
                                        />
                                        {light[1].isLoading &&
                                        <FontAwesomeIcon
                                            className='lightIconLeftSpinner'
                                            icon='spinner'
                                            fixedWidth
                                            color={light[1].state.on ? 'black' : 'white'}
                                            spin
                                        />
                                        }
                                    </span>
                                </ListItemIcon>
                                <ListItemText
                                    // primary={`${light[1].name}`}
                                    primary={
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    className='lighNameCheckBox'
                                                    disabled={!light[1].state.reachable}
                                                    checked={light[1].state.on}
                                                    onChange={(e) => {e.preventDefault()}
                                                    }
                                                />
                                            }
                                            label={`${light[1].name}`}
                                            onClick={(e)=>{e.preventDefault()}}
                                        />
                                    }
                                    title={`${light[1].type} - ${light[1].productname}`}
                                />
                                {light[1].state.reachable && light[1].state.effect &&
                                <ListItemSecondaryAction>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                icon={
                                                    <FontAwesomeIcon
                                                        icon={['fal', (light[1].isFxLoading ? 'ellipsis-h' : 'magic')]}
                                                        fixedWidth
                                                        // color={light[1].state.on ? 'black': 'white'}
                                                    />
                                                }
                                                checkedIcon={
                                                    <FontAwesomeIcon
                                                        icon={['fal', (light[1].isFxLoading ? 'ellipsis-h' : 'magic')]}
                                                        fixedWidth
                                                        // color='white'
                                                        className='colorimation'
                                                    />
                                                }
                                                value={light[0]}
                                                checked={light[1].state.effect === 'colorloop'}
                                            />
                                        }
                                        label={light[1].state.effect}
                                        onChange={lightToggleEffect}
                                    />
                                </ListItemSecondaryAction>
                                }
                                {/*{light[1].name}*/}
                            </ListItem>
                        )
                    }))}
                </List>
                }
            </div>


        </div>
    )
}

const Spinner = () => (
    <div className="spinner">
        <CircularProgress color="inherit"/>
        <h3> Loading.. </h3>
    </div>
)

export default AllInOne