import React, { useReducer } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './bgEffect.css'
import Context from './context'
import AppRouter from './routes'
import * as serviceWorker from './serviceWorker';

import * as Reducers from './reducers'

//FontAwesome:
import './fontAwesomeSetup'


const Wrapper = () =>{

    const [ state, dispatch ] = useReducer(Reducers.MainReducer, Reducers.globalInitialState)

    const contextDispatchUpdatePartial = (payload) => {
        console.log('HERE AT contextDispatchUpdatePartial!!!!!! :)', payload)
        dispatch({
            type: 'UPDATE_PARTIAL',
            payload
        })
    }
    return (
        <Context.Provider
            value={{
                state,
                dispatch: contextDispatchUpdatePartial
            }}
        >
            <AppRouter />
        </Context.Provider>
    )
}

ReactDOM.render(
    <Wrapper/>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
