import React from 'react'
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom"

import AppBar from './Components/AppBar'

import App from './App'
import Dashboard from "./Pages/Dashboard";
import Callback from './Pages/Callback'


import { withAuthenticator } from "aws-amplify-react";
import Amplify, { Auth } from "aws-amplify";
import AllInOne from "./Pages/AllInOne";

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: 'us-east-1',
        userPoolId: `${process.env.REACT_APP_KUGELBLITZ_USERPOOLID}`,
        userPoolWebClientId: `${process.env.REACT_APP_KUGELBLITZ_CLIENTID}`
    }
});


const About = () => <h2>About!</h2>
const User = () => <h2>User!</h2>

const Topic = ({ match }) => {
    return <h3>Requested Param: {match.params.id}</h3>;
}
function Topics({ match }) {
    return (
        <div>
            <h2>Topics</h2>

            <ul>
                <li>
                    <Link to={`${match.url}/components`}>Components</Link>
                </li>
                <li>
                    <Link to={`${match.url}/props-v-state`}>Props v. State</Link>
                </li>
            </ul>

            <Route path={`${match.path}/:id`} component={Topic} />
            <Route
                exact
                path={match.path}
                render={() => <h3>Please select a topic.</h3>}
            />
        </div>
    );
}

function NoMatch({ location }) {
    return (
        <div>
            <h3>
                No match for <code>{location.pathname}</code>
            </h3>
        </div>
    );
}


function AppRouter(){
    return (
        <Router>
            <AppBar/>
            {/*<nav>*/}
                {/*<ul>*/}
                    {/*<li>*/}
                        {/*<Link to='/'>Home</Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                        {/*<Link to='/about/'>About</Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                        {/*<Link to='/User/'>User</Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                        {/*<Link to="/topics">Topics</Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                        {/*<Link to="/Dashboard">Dashboard</Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                        {/*<Link to="/Callback?test=value&test2=value2">Callback</Link>*/}
                    {/*</li>*/}
                {/*</ul>*/}
            {/*</nav>*/}

            <Switch>
                <Route path='/' exact component={AllInOne}/>
                <Route path='/about' exact component={About}/>
                <Route path='/dashboard' exact component={Dashboard}/>
                <Route path='/User' exact component={User}/>
                <Route path="/topics" component={Topics} />
                <Route path="/callback" component={Callback} />
                <Route component={NoMatch} />
            </Switch>
        </Router>
    )
}

export default withAuthenticator(AppRouter);