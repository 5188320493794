import React, { useState, useEffect, useReducer, useContext} from 'react'
import * as Reducer from '../reducers'

import Context from '../context'

import UserDetails from '../Components/UserDetails'

const Dashboard = () => {

    const context = useContext(Context)

    const [ state, setState ] = useState('initial value')
    const [ state2, setState2 ] = useState()
    useEffect(() => {
        setTimeout(()=> setState(Math.random()), 666)
    }, [state2])
    const [ state3, dispatch ] = useReducer(Reducer.MainReducer, Reducer.initialState)

    const handleDispatchTest = () => {
        dispatch({
            type: 'UPDATE_PARTIAL',
            payload: { initialState: 'NOT initial!' }
        })
    }

    const handleDispatchTest2 = () => {
        dispatch({
            type: 'UPDATE_PARTIAL',
            payload: { initialState2: 'NOT initial either!' }
        })
    }


    return (
        <div>
            <UserDetails/>
            <p>
                {state}
            </p>
            <h1>Dashboard page.</h1>
            <button onClick={()=>{setState2(Math.random())}}>Get user details</button>
            <button onClick={()=>{handleDispatchTest(Math.random())}}>TEST DISPATCH</button>
            <button onClick={()=>{handleDispatchTest2(Math.random())}}>TEST DISPATCH2</button>
            <button onClick={()=>{context.dispatch({globalInitialState:`${Math.random()}`})}}>TEST DISPATCH GLOBAL!!!</button>

            <h2>1.{state3.initialState}</h2>
            <h2>2.{state3.initialState2}</h2>
            <h2>3 GLOBAL:::: .{context.state.globalInitialState}</h2>

        </div>)
}

export default Dashboard