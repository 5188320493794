import React, { useState, useContext } from 'react'

import Context from '../context'

import { Auth } from 'aws-amplify'
import axios from 'axios'


const UserDetails = (props) => {

    const context =  useContext(Context)
    const localState = {
        isUserLoading: false
    }
    const [ state, setState ] = useState(localState)


    const handleFetchUser = () =>{
        console.log('Handle fetch user')
        Auth.currentAuthenticatedUser()
            .then( user => {
                console.log('user', user)
                axios.get('https://pqj2pl7r3h.execute-api.us-east-1.amazonaws.com/dev/user',
                    {
                        headers: {"Authorization": user.signInUserSession.idToken.jwtToken}
                    })
                    .then( res => {
                        console.log('got user:', res.data)
                        context.dispatch({user: res.data.user})
                    })
                    .catch(err=> {
                        console.log('err getting user:', err)
                    })
            })
            .catch(err => {
                console.log('err:', err)
            })
    }

    return(
        <div>
            <p>user details component here!!! !</p>
            <button onClick={ handleFetchUser }>Fetch User!</button>
            <div>
                {!context.state.user ?
                    <h3>User needs to be fetched!</h3>:
                    <h3>User is fetched !!!</h3>
                }
            </div>
        </div>
    )
}

export default UserDetails