import React, { useEffect } from 'react'

import { Auth } from 'aws-amplify'
import axios from 'axios'


const Callback = () => {

    const queryString = window.location.search
    const callBackEndpoint = `${process.env.REACT_APP_KUGELBLITZ_AUTH}/callback/`

    useEffect(()=>{
        console.log('urlParams: ', queryString)

        Auth.currentAuthenticatedUser()
            .then( user => {
                console.log('user', user)
                axios.get(`${callBackEndpoint}${queryString}`,
                    {
                        headers: {"Authorization": user.signInUserSession.idToken.jwtToken}
                    })
                    .then( res => {
                        console.log('got callback response:', res)
                        window.location.replace('../')
                    })
                    .catch(err=> {
                        console.log('err getting callback :', err)
                    })
            })
            .catch(err => {
                console.log('err:', err)
            })
    }, [])


    return(
        <div>
            <p>Please wait...</p>
            <p>You'll be redirected momentarily.</p>
        </div>
    )
}

export default Callback